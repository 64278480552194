<template>
  <div class="teams">
    <navbar bg="#fdf1e8" :fixed="true" />
    <div class="teams-inner">
      <div class="type-tabs">
        <div
          class="tab"
          :class="{ active: activeTab === TYPES.first }"
          @click="changeType(TYPES.first)"
        >
          直推粉丝
        </div>
        <div
          v-if="userInfo.vip_level === 3"
          class="tab"
          :class="{ active: activeTab === TYPES.second }"
          @click="changeType(TYPES.second)"
        >
          间推粉丝
        </div>
      </div>
      <div v-if="activeTab === TYPES.first" class="teams-list">
        <van-list
          v-model="loading"
          class="list-content"
          :finished="finished"
          finished-text="没有更多了"
          @load="fetchList"
        >
          <div v-for="item in resultList" :key="item.id" class="list-item">
            <div class="left">
              <div class="nick">{{ item.real_name }}</div>
              <div class="mobile">{{ item.mobile }}</div>
            </div>
            <div class="right">
              <div class="vip-level" :class="{ 'is-vip': item.vip_level > 0 }">
                {{ userVip(item.vip_level).levelname || "普通用户" }}
              </div>
            </div>
          </div>
        </van-list>
      </div>
      <div v-else class="teams-list">
        <van-list
          v-model="loading"
          class="list-content"
          :finished="finished"
          finished-text="没有更多了"
          @load="fetchList"
        >
          <div v-for="item in resultList" :key="item.id" class="list-item">
            <div class="left">
              <div class="nick">{{ item.real_name }}</div>
              <div class="mobile">{{ item.mobile }}</div>
            </div>
            <div class="right">
              <div
                class="vip-level small"
                :class="{ 'is-vip': item.vip_level > 0 }"
              >
                {{ userVip(item.vip_level) || "普通用户" }}
              </div>
              <div class="second-up">{{ item.from_user_name }}</div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { List } from "vant";
import apis from "@/apis/index";
import Navbar from "@/components/h5/Navbar";

const TYPES = {
  first: "first",
  second: "second"
};

// const mockData = new Array(30).fill({}).map((item, index) => ({
//   ...item,
//   id: index + 1,
//   real_name: "名字1",
//   mobile: "18573309100",
//   vip_level: [0, 1][index % 2]
// }));

export default {
  name: "Teams",
  components: {
    "van-list": List,
    Navbar
  },
  data() {
    return {
      TYPES,
      page: 1,
      finished: false,
      loading: false,
      resultList: [],
      activeTab: TYPES.first,
      currApi: apis.userTeams
    };
  },
  computed: {
    ...mapState(["userInfo", "vipIndex"])
  },
  mounted() {
    // this.fetchList();
  },
  methods: {
    async fetchList() {
      try {
        const {
          data: { user_list, max_page }
        } = await this.currApi({ page: this.page });
        this.page += 1;
        if (this.page >= max_page) {
          this.finished = true;
        }
        console.log("fetch");
        this.resultList = [...this.resultList, ...user_list];
      } catch (error) {
        this.finished = true;
        console.log(error);
      }
      this.loading = false;
    },
    changeType(type) {
      this.activeTab = type;
      this.currApi =
        type === TYPES.first ? apis.userTeams : apis.secondUserTeams;
      this.refreshList();
    },
    refreshList() {
      this.page = 1;
      this.finished = false;
      this.loading = true;
      this.resultList = [];
      this.fetchList();
    },
    userVip(level) {
      return this.vipIndex.find(item => item.id === level) || {};
    }
  }
};
</script>

<style lang="scss">
.van-list__loading {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.teams {
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  .teams-inner {
    margin: 40px;
    height: 100%;
    /* prettier-ignore */
    // min-height: calc(100vh - 96PX - 40px);
  }

  .type-tabs {
    display: flex;

    .tab {
      position: relative;
      width: 240px;
      height: 90px;
      background: #f7f7f7;
      border-radius: 10px 10px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 3px;
      margin-right: 10px;
      font-size: 44px;
      color: #8a574d;
      cursor: pointer;

      &.active {
        opacity: 1;
        color: #ffffff;
        background-color: #CA0A15;
      }
    }
  }

  .teams-list {
    background-color: #fff;
    padding: 40px;
    min-height: 600px;

    // min-height: calc(100% - 90px);

    // height: calc(100% - 46px);
    // padding-top: 20px;

    // .list-header {
    //   height: 40px;
    //   background: #ffffff;
    //   border-radius: 4px;
    //   color: #333333;
    //   font-size: 14px;
    //   display: flex;
    //   justify-content: space-evenly;
    //   align-items: center;
    //   padding: 0 20px;
    // }

    .list-content {
      display: flex;
      flex-flow: column nowrap;
      // min-height: 100%;

      // overflow: auto;
      // height: calc(100% - 60px);
      // flex: none;

      .list-item {
        flex: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 965px;
        height: 170px;
        background: #f7f7f7;
        border-radius: 10px;
        margin-top: 40px;
        padding: 0 40px;
        box-sizing: border-box;

        .left {
          .nick {
            // height: 36px;
            font-size: 44px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }

          .mobile {
            // height: 24px;
            font-size: 40px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            margin-top: 30px;
          }
        }

        .right {
          .vip-level {
            // height: 43px;
            font-size: 56px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;

            &.small {
              font-size: 44px;
            }

            &.is-vip {
              color: #f02b2b;
            }
          }
          .second-up {
            // height: 30px;
            font-size: 40px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
        }

        &:first-of-type {
          margin-top: 0px;
        }
      }
    }
  }
}
</style>
